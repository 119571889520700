/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Odhad nemovitosti"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--80 pt--80" style={{"backgroundColor":"#f5f8fd"}} name={"ocpl270ihkd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Chci odhad\n"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1070,"marginTop":22}} content={"V procesu odhadu nemovitosti se opírám o své bohaté zkušenosti a nejaktuálnější data a trendy na trhu. Je důležité si uvědomit, že i přes veškerou snahu a odbornost, každý odhad může nést jistou míru subjektivity. Nicméně, díky mému pečlivému přístupu a důkladné analýze, poskytuji odhady, které jsou co nejpřesnější a odrážejí současnou tržní hodnotu nemovitostí. Mým cílem je nabídnout vám informace, které vám umožní učinit nejlepší možná rozhodnutí.\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"odhad"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" style={{"paddingLeft":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":596}}>
              
              <Title className="title-box fs--30" content={"Odhad - jak na určení správné ceny\n\n"}>
              </Title>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Stanovit cenu nemovitosti je jako skládat puzzle. Kromě aktuálních nabídkových a prodejních cen v dané lokalitě beru v potaz celou řadu faktorů - od specifik a potenciálu nemovitosti, přes její stav, až po energetickou náročnost a samozřejmě polohu. Tento komplexní pohled mi umožňuje připravit odhad, který je co nejblíže realitě a zároveň vyzdvihuje to nejlepší, co vaše nemovitost nabízí."}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Mé metody zahrnují důkladnou analýzu trhu a porovnání s podobnými nemovitostmi, aby byl odhad co nejpřesnější. Sleduji trendy, vývoj cen a snažím se předvídat budoucí vývoj na trhu. Díky tomu můžu klientům nabídnout nejen odhad, ale také strategii, jak svou nemovitost co nejvýhodněji prodat.\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":35}} content={"Pokud hledáte někoho, kdo k odhadu přistupuje s lidským přístupem a zároveň má na paměti komplexnost celého procesu, jsem tu právě pro vás. Společně odhalíme skutečnou hodnotu vašeho domova, zvýrazníme jeho přednosti a najdeme tu správnou cenu, která uspokojí jak vás, tak potenciálního kupce.\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape3" action={"contact"} name={"9velcwwvqg"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"ndsbszx345p"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"hx6mo2bteq"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"mbhc4li3ha"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"uudb3rmixl8"},{"name":"Odeslat","type":"submit","id":"chakkgeyurq"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c4hlub css-c2cxkz --parallax pb--60 pt--60" name={"prefooter"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/23336/0f414deeb13b4c22aa6f525a6b1c1f29_s=3000x_.png);
    }
  
background-position: 50% 25%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 fs--48 w--600 lh--14 pb--06 pt--06" style={{"maxWidth":824}} content={"<span style=\"color: var(--color-custom-2);\">Stavíme mosty k vašim vysněným nemovitostem\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4" style={{"marginTop":0}} content={"Kontaktovat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}